import axios from "axios";
import { useCallback, useState } from "react";

const useApi = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const apiCall = useCallback(async (config) => {
    setApiLoading(true);
    setApiError(null);

    try {
      const response = await axios(config);
      return response.data; // Assuming you want the data part of the response.
    } catch (err) {
      setApiError(err.response ? err.response.data : "Network Error");
      return null; // You can return null or customize this based on how you handle errors.
    } finally {
      setApiLoading(false);
    }
  }, []);

  return { apiCall, apiLoading, apiError };
};

export default useApi;
